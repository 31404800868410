const country_model = {
    country_name: "",
    country_code: "",
    logo_url: ""
}

const countries_authorized_urls = [
    '/banks',
    '/'
]

export {countries_authorized_urls, country_model}