import $ from 'jquery';
import axios from "axios";

const apiUrl = "/api"

const requests = {
    get: {},
    post: {},
    delete: {}
};

const asyncRequest = async (requestMethod, requestUrl, requestData) => {
    requestUrl = requestUrl.replace(apiUrl,  "");

    const spinner = $('.loading-spinner');
    spinner.hide();

    requestMethod = requestMethod.toLowerCase();

    if (requests[requestMethod])
        delete requests[requestMethod][requestUrl];


    const success = (response) => {
        spinner.hide();
        delete requests[requestMethod][requestUrl];
        return {
            error: null,
            response: response.data
        }
    }

    const error = (reason) => {
        spinner.hide();
        delete requests[requestMethod][requestUrl];
        return {
            error: reason,
            response: {}
        }
    }

    spinner.show();

    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    requests[requestMethod][requestUrl] = null;

    if (requestMethod === "post") {
        return axios.post(`${apiUrl}${requestUrl}`, requestData, config).then(success).catch(error);
    }
    else if (requestMethod === "get")
        return axios.get(`${apiUrl}${requestUrl}`, config).then(success).catch(error)
    else if (requestMethod === "delete")
        return axios.delete(`${apiUrl}${requestUrl}`, config).then(success).catch(error);
    else
        spinner.hide();
}

export {asyncRequest};
