import React, {useEffect, useRef, memo} from 'react';
import Button from "react-bootstrap/Button";
import Dashboard from "../Dashboard/dashboard";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getUserInfo, logout, setUserInfo} from "../../Helpers/Requests/Auth";
import "./Assets/css/settings.css";
import {settings_authorized_path, userModel} from "../../Helpers/Urls/settings";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import $ from 'jquery';

const Settings = ({destroy}) => {
    const userInfo = useRef(userModel);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(()=>{
        if (!settings_authorized_path.includes(location.state?.from)){
            navigate("/");
            return
        }

        if (!userInfo.current.name)
            (async () => {
                userInfo.current = await getUserInfo();
            })();
    }, [location.state?.from, navigate]);

    const saveUserInfo = async () => {
        const username = $('#username')[0].value;
        const password = $('#password')[0].value;
        if (await setUserInfo(username, password)){
            await logout();
            destroy();
            navigate('/');
        }
    }

    return (
        <Dashboard
            header={
                <Link to="/" className="button-link">
                    <Button variant="primary">Back</Button>
                </Link>
            }
            body={{
                title: <div className="d-flex align-items-center flex-column">
                    <h4>account settings</h4>
                </div>,
                content: <div className="d-flex flex-wrap flex-column justify-content-center align-items-center">
                    <div className="w-100">
                        <FloatingLabel
                            label="username"
                            className="mb-3"
                        >
                            <Form.Control id="username" type="text" placeholder="username" />
                        </FloatingLabel>
                        <FloatingLabel label="Password">
                            <Form.Control id="password" type="password" placeholder="Password" />
                        </FloatingLabel>
                        <Form.Control type="submit" className="btn btn-warning mt-3" value="save" onClick={async ()=>{await saveUserInfo()}}/>
                    </div>
                </div>
            }}
        >
        </Dashboard>
    )
}

export default memo(Settings);