import {asyncRequest} from "./Requests";

const fetchAccounts = async (query="")=>{
    const data = await asyncRequest('get', `/accounts?${query}`);
    return data.response;
}

const fetchAccountBalances = async (accountId, query="")=>{
    const data = await asyncRequest('get', `/accounts/${accountId}/balances?${query}`);
    return data.response;
}

const fetchAccountTransactions = async (accountId, query="")=>{
    const data = await asyncRequest('get', `/accounts/${accountId}/transactions?${query}`);
    return data.response;
}

const deleteAccount = async (accountId)=>{
    const data = await asyncRequest('get', `/accounts/delete/${accountId}`);
    return data.response;
}

export {
    fetchAccounts,
    fetchAccountTransactions,
    fetchAccountBalances,
    deleteAccount
};
