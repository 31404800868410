import {asyncRequest} from "./Requests";

const user = async ()=>{
    const data = await asyncRequest('get', "/user");
    return data.response;
}

const login = async (username, password) => {
    const data = await asyncRequest('post',
        '/login', {
            username: username,
            password: password
        });
    if (data.response?.logged){
        localStorage.setItem('token', data.response.token);
    }
    return data.response;
}

const setUserInfo = async (username, password) => {
    const data = await asyncRequest('post', '/user', {
        'username': username,
        'password': password
    });
    return data.response?.status;
}

const logout = async () => {
    const data = await asyncRequest('delete',"/logout");
    if (data.response?.logout) {
        localStorage.removeItem('token');
        return true;
    }
    return false;
}

const getUserInfo = async () => {
    const data = await asyncRequest('get', '/user');
    return data.response?.info;
}

export {
    login,
    user,
    logout,
    getUserInfo,
    setUserInfo
};
