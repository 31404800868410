import {FormControl, InputGroup} from "react-bootstrap";
import {InfoModal} from "./InfoModal";

const Account = ({cardHolderName, accountInfo}) => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse" data-bs-target={`#account-${accountInfo.id}`}>
                    {cardHolderName}
                </button>
            </h2>
            <div id={`account-${accountInfo.id}`} className="accordion-collapse collapse">
                <div className="accordion-body">
                    {
                        Object.keys(accountInfo).map(function (key){
                            if (['id', 'user_id', 'created_at', 'updated_at'].includes(key)) return "";
                            return (
                                <InputGroup key={`account-${accountInfo.id}-${key}`} className="mb-1">
                                    <InputGroup.Text style={{width: "200px"}}>
                                        {key.replace('_', " ").toUpperCase()}
                                    </InputGroup.Text>
                                    <FormControl
                                        style={{overflow: "hidden", minHeight: "50px"}}
                                        placeholder={key}
                                        disabled="disabled"
                                        className="text-center"
                                        value={accountInfo[key] ?? '------'}
                                    />
                                </InputGroup>
                            )
                        })
                    }
                    <div className="d-flex justify-content-center mt-2">
                        <InfoModal modalName="Transactions" accountId={accountInfo.id}></InfoModal>
                        <InfoModal modalName="Balances" accountId={accountInfo.id}></InfoModal>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Account;
