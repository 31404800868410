import {asyncRequest} from "./Requests";

const fetchBanks = async (country_code)=>{
    const data = await asyncRequest('post', "/banks", {
        country_code: country_code
    });
    return data.response?.banks;
}

const fetchBankAuthenticationUrl = async (bank_id)=>{
    const data = await asyncRequest('post', "/bankAuthentication", {
        bank_id: bank_id
    });
    console.log(data.response);
    return data.response?.link;
}

export {
    fetchBankAuthenticationUrl,
    fetchBanks
};
