import Dashboard from "../Dashboard/dashboard";
import Button from "react-bootstrap/Button";
import {Form, InputGroup, Pagination} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {logout} from "../../Helpers/Requests/Auth";
import Account from "./Account";
import {fetchAccounts} from "../../Helpers/Requests/Accounts";
import {useEffect, useState} from "react";
import {Paginate} from "../Paginate/Paginate";

const Accounts = ({destroy, is_admin}) => {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    // const [search, setSearch] = useState("");

    useEffect(()=>{
        (async () => {
            const accountsData = await fetchAccounts();
            setAccounts(accountsData);
        })();

    }, []);

    return(
        <Dashboard
            header={
                <div className="d-flex flex-sm-column">
                    {
                        is_admin ? (
                            <Link className="button-link d-flex" to="/add_user" state={{
                                from: "/"
                            }}>
                                <Button variant="secondary mb-1 w-100">
                                    Add New User
                                </Button>
                            </Link>
                        ) : (<></>)
                    }
                    <Link className="button-link d-flex" to="/countries" state={{
                        from: "/"
                    }}>
                        <Button variant="primary mb-1 w-100">
                            Add New Account
                        </Button>
                    </Link>
                    <Link className="button-link d-flex" to="/user" state={{
                        from: "/"
                    }}>
                        <Button variant="success w-100 mb-1">
                            Settings
                        </Button>
                    </Link>
                    <Button variant="danger w-100" onClick={async function(){
                        await logout();
                        navigate('/login')
                        destroy();
                    }}>Logout</Button>
                </div>
            }
            body={
                {
                    title:
                        <div className="d-flex flex-column align-items-center">
                            <h4>Available Accounts</h4>
                            {/*<InputGroup className="m-2 col-6" style={{maxWidth: "300px"}}>*/}
                            {/*    <Form.Control*/}
                            {/*        placeholder="Search" onChange={e => {setSearch(e.target.value)}}*/}
                            {/*    />*/}
                            {/*    <Button variant="success"  onClick={async () => {*/}
                            {/*        const data = await fetchAccounts(`search=${search}`);*/}
                            {/*        setAccounts(data);*/}
                            {/*    }*/}
                            {/*    }>*/}
                            {/*        Search*/}
                            {/*    </Button>*/}
                            {/*</InputGroup>*/}
                        </div>,
                    content:
                    <>
                        <div className="accordion accordion-flush">
                            {
                                accounts?.data?.map(function(value){
                                    return (<Account key={value.id} cardHolderName={`${value['Card Holder Name']}`} accountInfo={value}/>)
                                })
                            }
                        </div>
                        <Paginate paginateData={accounts} callbacks={{
                            setPaginate: setAccounts
                        }} />
                    </>
                }
            }
        />
    )
}

export default  Accounts;
