import {Pagination} from "react-bootstrap";
import {asyncRequest} from "../../Helpers/Requests/Requests";

const fetchPaginateUrl = async (pageUrl) => {
        const paginateData = await asyncRequest('get', pageUrl);
        return paginateData.response;
}

const callbacksTrigger = (trigger) => {
    if  (typeof trigger === 'function')
        trigger();
}

const setPaginateCallback = (trigger, paginateData) => {
    if (typeof trigger ===  'function')
        trigger(paginateData);

}

const Paginate = ({paginateData={
    curren_page: 1,
    first_page_url: null,
    prev_page_url: null,
    last_page_url: null,
    next_page_url: null,
    links: []
}, callbacks={
    current_page: null,
    first_page_url: null,
    prev_page_url: null,
    last_page_url: null,
    next_page_url: null,
    links: [],
    setPaginate: null}}) => {
        // return (
        //     <div className="d-flex justify-content-center mt-3">
        //         <Pagination>
        //             <Pagination.First onClick={
        //                 async ()=> {
        //                     if (paginateData?.first_page_url) {
        //                         const data = await fetchPaginateUrl(paginateData.first_page_url);
        //                         callbacksTrigger(callbacks?.first_page_url);
        //                         setPaginateCallback(callbacks?.setPaginate, data);
        //                     }
        //                 }
        //             }
        //             />
        //
        //             <Pagination.Prev onClick={
        //                     async ()=> {
        //                         if (paginateData?.prev_page_url) {
        //                             const data = await fetchPaginateUrl(paginateData.prev_page_url);
        //                             callbacksTrigger(callbacks?.prev_page_url);
        //                             setPaginateCallback(callbacks?.setPaginate, data);
        //                         }
        //                     }
        //                 }
        //             />
        //
        //             {
        //                 paginateData?.links?.map(function(link, index){
        //                     if  (link.url)
        //                         return  (
        //                             <Pagination.Item active={link.active} key={index} onclick={
        //                                 async () => {
        //                                     const data = await fetchPaginateUrl(link.url);
        //                                     callbacksTrigger(callbacks?.links[index]);
        //                                     setPaginateCallback(callbacks?.setPaginate, data);
        //                                 }
        //                             }>
        //                                 {link.label}
        //                             </Pagination.Item>
        //                         )
        //                 })
        //             }
        //
        //             <Pagination.Next onClick={
        //                 async ()=> {
        //                     if (paginateData?.next_page_url) {
        //                         const data = await fetchPaginateUrl(paginateData.next_page_url);
        //                         callbacksTrigger(callbacks?.next_page_url);
        //                         setPaginateCallback(callbacks?.setPaginate, data);
        //                     }
        //                 }
        //             }
        //             />
        //
        //             <Pagination.Last onClick={
        //                 async ()=> {
        //                     if (paginateData?.last_page_url) {
        //                         const data = await fetchPaginateUrl(paginateData.last_page_url);
        //                         callbacksTrigger(callbacks?.last_page_url);
        //                         setPaginateCallback(callbacks?.setPaginate, data);
        //                     }                                        }
        //             }
        //            />
        //         </Pagination>
        //     </div>
        // )
    return "";
}

export {
    Paginate
}
