import React, {useEffect, memo, useState} from 'react';
import Button from "react-bootstrap/Button";
import Dashboard from "../Dashboard/dashboard";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./Assets/admin.css";
import {admin_authorized_urls} from "../../Helpers/Urls/admin";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import $ from 'jquery';
import createUserRequest from "../../Helpers/Requests/Admin";


const Admin = ({is_admin}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(null);
    const [alertMessage, setAlertMessage] = useState({});

    useEffect(()=>{
        if (!admin_authorized_urls.includes(location.state?.from) || !is_admin)
            navigate("/");

    }, [location.state?.from, navigate, is_admin]);

    const createUser = async () => {
        let is_valid = true;
        const username = $("#username");
        const password = $("#password");
        const email = $("#email");
        const app_key = $("#nordigen-app-key");
        const app_id = $("#nordigen-app-id");

        username.removeClass('is-invalid');
        password.removeClass('is-invalid');
        email.removeClass('is-invalid');
        app_id.removeClass('is-invalid');
        app_key.removeClass('is-invalid');

        if (!username.val().trim() || username.val().length < 5){
            username.addClass('is-invalid');
            is_valid = false;
        }
        if (!password.val().trim() || password.val().length < 8){
            password.addClass('is-invalid');
            is_valid = false;
        }
        if (!email.val().trim()){
            email.addClass('is-invalid');
            is_valid = false;
        }
        if (!app_id.val().trim()){
            app_id.addClass('is-invalid');
            is_valid = false;
        }
        if (!app_key.val().trim()){
            app_key.addClass('is-invalid');
            is_valid = false;
        }

        if (is_valid){
            const response = await createUserRequest({
                username: username.val(),
                password: password.val(),
                email: email.val(),
                secret_id: app_id.val(),
                secret_key: app_key.val()
            });

            setShowAlert(response?.status !== null);
            setAlertType(response?.status ? "success" : "danger");
            setAlertMessage(response?.status ? response?.message : (response?.errors))
        }
    }

    return (
        <Dashboard
            header={
                <Link to="/" className="button-link">
                    <Button variant="primary">Back</Button>
                </Link>
            }
            body={{
                title: <div className="d-flex align-items-center flex-column">
                    <h4>account settings</h4>
                </div>,
                content: <div className="d-flex flex-wrap flex-column justify-content-center align-items-center">
                    {
                         showAlert && (
                            <div className={"alert alert-" + alertType}>
                                {
                                    Object.keys(alertMessage).map(function(key){
                                        return <span className="mb-1" style={{display: "block"}}>
                                            {alertMessage[key]}
                                        </span>
                                    })
                                }
                            </div>
                        )
                    }
                    <div className="w-100">
                        <FloatingLabel
                            label="username"
                            className="mb-3"
                        >
                            <Form.Control id="username" type="text" placeholder="username" name="username" />
                        </FloatingLabel>
                        <FloatingLabel
                            label="email"
                            className="mb-3"
                        >
                            <Form.Control id="email" type="email" placeholder="email" name="email" />
                        </FloatingLabel>
                        <FloatingLabel label="Password"
                                       className="mb-3"
                        >
                            <Form.Control id="password" type="password" placeholder="Password" name="password" />
                        </FloatingLabel>
                        <FloatingLabel label="Nordigen Secret Key"
                                       className="mb-3"
                        >
                            <Form.Control id="nordigen-app-key" type="text" placeholder="Nordigen Secret Key" name="secret_key" />
                        </FloatingLabel>
                        <FloatingLabel label="Nordigen Secret ID">
                            <Form.Control id="nordigen-app-id" type="text" placeholder="Nordigen Secret ID" name="secret_id"/>
                        </FloatingLabel>
                        <Form.Control type="submit" className="btn btn-warning mt-3" value="save" onClick={async ()=>{await createUser()}}/>
                    </div>
                </div>
            }}
        >
        </Dashboard>
    )
}

export default memo(Admin);