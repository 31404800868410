import {Modal, Button, InputGroup, Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {fetchAccountBalances, fetchAccountTransactions} from "../../Helpers/Requests/Accounts";
import {Paginate} from "../Paginate/Paginate";




const InfoModal = ({modalName, accountId}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [search, setSearch] = useState("");
    const [modalData, setModalData] = useState({});

    const columns = () => {
        switch(modalName){
            case "Transactions":
                return [
                    {
                        name: "Transaction Code",
                        selector: (row) => (row.transaction_code) ? row.transaction_code : "--------",
                        sortable: true
                    },
                    {
                        name: "Transaction Amount",
                        selector: (row) => (row.transaction_amount) ? row.transaction_amount: "--------",
                        sortable: true
                    },
                    {
                        name: "Transaction Currency",
                        selector: (row) => (row.transaction_currency) ? row.transaction_currency : "--------",
                        sortable: true
                    },
                    {
                        name: "Value Date",
                        selector: (row) => (row.value_date) ? row.value_date : "--------",
                        sortable: true
                    },
                    {
                        name: "Booking Date",
                        selector: (row) => (row.booking_date) ? row.booking_date : "--------",
                        sortable: true
                    },
                    {
                        name: "Status",
                        selector: (row) => (row.status) ? row.status : "--------",
                        sortable: true
                    },
                    {
                        name: "Remittance Information Unstructured Array",
                        selector: (row) => row.remittance_information_unstructured_array ? row.remittance_information_unstructured_array : '--------',
                        sortable: true
                    }
                ]

            case "Balances":
                return [
                    {
                        name: "Balance Amount",
                        selector: (row) => (row.balance_amount) ? row.balance_amount : "--------",
                        sortable: true
                    },
                    {
                        name: "Balance Currency",
                        selector: (row) => (row.balance_currency) ? row.balance_currency: "--------",
                        sortable: true
                    },
                    {
                        name: "Balance Type",
                        selector: (row) => (row.balance_type) ? row.balance_type : "--------",
                        sortable: true
                    },
                    {
                        name: "Reference Date",
                        selector: (row) => (row.reference_date) ? row.reference_date : "--------",
                        sortable: true
                    },
                ]

            default:
                return [];
        }
    }

    useEffect(()=>{}, [modalData]);

    return (
        <>
            <Button variant="primary" className="m-1" onClick={async () => {
                let data = [];
                switch (modalName){
                    case "Transactions":
                        data = await fetchAccountTransactions(accountId);
                        break;

                    case "Balances":
                        data = await fetchAccountBalances(accountId);
                        break;
                    default:
                }
                setModalData(data);
                handleShow();
            }}>
                {modalName}
            </Button>

            <Modal show={show} onHide={handleClose} fullscreen>
                <Modal.Header closeButton className="d-flex flex-column">
                    <Modal.Title>{modalName}</Modal.Title>
                    {/*<div className="mt-1">*/}
                    {/*    <InputGroup className="mb-3">*/}
                    {/*        <Form.Control*/}
                    {/*            placeholder="Search" onChange={e => {setSearch(e.target.value)}}*/}
                    {/*        />*/}
                    {/*        <Button variant="success"  onClick={async () => {*/}
                    {/*                let data = [];*/}

                    {/*                switch (modalName){*/}
                    {/*                    case "Transactions":*/}
                    {/*                        data = await fetchAccountTransactions(accountId, `search=${search}`);*/}
                    {/*                        break;*/}

                    {/*                    case "Balances":*/}
                    {/*                        data = await fetchAccountBalances(accountId, `search=${search}`);*/}
                    {/*                        break;*/}

                    {/*                    default:*/}
                    {/*                }*/}
                    {/*                setModalData(data);*/}
                    {/*            }*/}
                    {/*        }>*/}
                    {/*            Search*/}
                    {/*        </Button>*/}
                    {/*    </InputGroup>*/}
                    {/*</div>*/}
                </Modal.Header>

                <Modal.Body>
                    <DataTable
                        data={modalData.data}
                        columns={columns()}
                    >
                    </DataTable>
                    <Paginate paginateData={modalData} callbacks={{
                        setPaginate: setModalData
                    }}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export {InfoModal}
