import React, {useEffect, useRef, useState} from 'react';
import Button from "react-bootstrap/Button";
import Dashboard from "../Dashboard/dashboard";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {fetchCountries} from "../../Helpers/Requests/Countries";
import "./Assets/css/countries.css";
import {countries_authorized_urls, country_model} from "../../Helpers/Urls/countries";

const Countries = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const availableCountries = useRef([]);
    const [countries, setCountries] = useState([country_model]);

    useEffect(()=>{
        if (!countries_authorized_urls.includes(location.state?.from)){
            navigate("/");
            return
        }

        if (!availableCountries.current.length)
            (async () => {
                const fetchedCountries = await fetchCountries()
                setCountries(fetchedCountries);
                availableCountries.current = fetchedCountries;
            })();
    }, [location.state?.from, navigate]);

    return (
        <Dashboard
            header={
                <Link to="/" className="button-link">
                    <Button variant="primary">Back</Button>
                </Link>
            }
            body={{
                title: <div className="d-flex align-items-center flex-column">
                            <h4>available countries</h4>
                            <div className="w-50 p-3">
                                <input type="text" className="form-control" placeholder="Search"
                                       onChange={
                                          function (event){

                                              let filtered_array = availableCountries.current.filter(function(item){
                                                  return item.country_name.toLowerCase().includes(event.target.value)
                                              })
                                              setCountries(filtered_array);
                                          }
                                       }
                                />
                            </div>
                      </div>,
                content: <div className="d-flex flex-wrap">
                        {
                            countries.map(function(item){
                                return (
                                    <Link key={item.country_code} className="button-link country-item col-6 border-bottom" to="/banks"
                                          state={{from: "/countries", country_code: item.country_code}}
                                    >
                                        <div className="d-flex align-items-center">
                                            <img className="country-image rounded-3 m-3" src={item.logo_url} alt="..."/>
                                            <h4>{item.country_name}</h4>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                </div>
            }}
        >
        </Dashboard>
    )
}

export default Countries;