import Button from "react-bootstrap/Button";
import {memo} from "react";
import "./Assets/dashboard.css";

const Dashboard = ({header, body}) => {
    return (
        <div className="dashboard-container bg-light">
            <div className="dashboard-header text-dark">
                <h1 className="col text-start me-3">Nordigen Management</h1>
                <div>
                    {header}
                </div>
            </div>
            <div className="dashboard-body border-top">
                <div className="dashboard-body-title text-secondary bg-light">
                    {body.title}
                </div>
                <div className="dashboard-body-content">
                    {body.content}
                </div>
            </div>
        </div>
    )
}

Dashboard.defaultProps = {
    header: <Button>Action Button</Button>,
    body: {
        title: 'Current Page Title',
        content: <div>Current Page Content</div>
    }
}
export default memo(Dashboard);