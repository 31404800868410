import React, {useEffect, memo} from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {user, login} from "../../Helpers/Requests/Auth";
import $ from 'jquery';
import "./Assets/css/login.css";


const Login = function({authenticate, setAdmin}){
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        (async function(){
            const user_info = await user();
            if (user_info?.logged) {
                authenticate();
                setAdmin(user_info?.info?.is_admin);
                navigate(location.state?.from || "/");
            }
        })();
    });

    const loginHandler = async function(){
        const username = document.getElementById("username")
        const password = document.getElementById("password");
        const login_response = await login(username.value, password.value);
        if (login_response?.logged){
            authenticate();
            setAdmin(login_response?.is_admin);
            navigate("/");
        }else{
            document.getElementById("error-message").innerText = "invalid username or password";
            $('#error-message').show();
            $('#error-message').fadeOut(500);
        }
    }

    return (

                <div className="border border-success rounded-3 login-inputs-container">
                    <div className="login-container-logo mb-5"></div>
                    <div className="container-error mb-5 text-danger" id="error-message"></div>
                    <FloatingLabel
                        controlId="login-email-input"
                        label="Username"
                        className="mb-3 w-100"
                    >
                        <Form.Control id="username" type="text" placeholder="Username" minLength="5" maxLength="16" required/>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="login-password-input"
                        label="Password"
                        className="mb-5 w-100"
                    >
                        <Form.Control id="password" type="password" placeholder="Password" minLength="5" maxLength="16" required/>
                    </FloatingLabel>
                    <Button variant="outline-info w-75" onClick={loginHandler}>Login</Button>
                </div>
    )
}

export default memo(Login);
