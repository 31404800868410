const bank_model = {
    bank_name: "ABN AMRO Bank Commercial",
    bank_id: "ABNAMRO_ABNAGB2LXXX",
    logo_url: "https://cdn.nordigen.com/ais/ABNAMRO_FTSBDEFAXXX.png"
}

const banks_authorized_url = [
    '/',
    '/countries'
]

export {bank_model, banks_authorized_url}