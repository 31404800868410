import React, {useEffect, useRef, useState} from 'react';
import Button from "react-bootstrap/Button";
import Dashboard from "../Dashboard/dashboard";
import {useNavigate, Link, useLocation} from "react-router-dom";
import {bank_model, banks_authorized_url} from "../../Helpers/Urls/banks";
import {fetchBankAuthenticationUrl, fetchBanks} from "../../Helpers/Requests/Banks";
import "./Assets/css/banks.css";

const Banks = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const availableBanks = useRef([]);
    const [banks, setBanks] = useState([bank_model]);

    useEffect(()=>{
        if (!banks_authorized_url.includes(location.state?.from)){
            navigate("/");
            return
        }
        if (!location.state?.country_code) {
            navigate("/countries");
            return;
        }
        (async () => {
            const fetchedBanks = await fetchBanks(location.state?.country_code)
            setBanks(fetchedBanks);
            availableBanks.current = fetchedBanks;
        })();

    }, [location.state?.country_code, location.state?.from, navigate]);
    return (
        <Dashboard
            header={
                <Link to="/countries" state={{from: "/banks"}}>
                    <Button variant="primary">Back</Button>
                </Link>
            }
            body={{
                title: <div className="d-flex align-items-center flex-column">
                    <h4>available banks</h4>
                    <div className="w-50 p-3">
                        <input type="text" className="form-control" placeholder="Search"
                               onChange={
                                   function (event){

                                       let filtered_array = availableBanks.current.filter(function(item){
                                           return item.bank_name.toLowerCase().includes(event.target.value)
                                       })
                                       setBanks(filtered_array);
                                   }
                               }
                        />
                    </div>
                </div>,
                content: <div className="d-flex flex-wrap">
                    {
                        banks.map(function(item){
                            return (
                                <div key={item.bank_id}
                                     onClick={async ()=>{
                                         const redirect_url = await fetchBankAuthenticationUrl(item.bank_id);
                                         if (redirect_url)
                                             window.location.href = redirect_url;
                                     }}
                                     className="bank-item col-6 d-flex align-items-center border-bottom">
                                    <img className="bank-image rounded-3 m-3" src={item.logo_url}/>
                                    <h4>{item.bank_name}</h4>
                                </div>
                            )
                        })
                    }
                </div>
            }}
        >
        </Dashboard>
    )
}

export default Banks;