import Login from "./Compontents/Login/Login";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Accounts from "./Compontents/Accounts/Accounts";
import Countries from "./Compontents/Countries/Countries";
import LoadingSpinner from "./Compontents/LoadingSpinner/LoadingSpinner";
import Banks from "./Compontents/Banks/Banks";
import Admin from "./Compontents/Admin/Admin";

import {memo, useState, useMemo} from "react";
import './App.css';
import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";
import routes from "./Helpers/Urls/routes";
import Settings from "./Compontents/Settings/settings";

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const [admin, setAdmin] = useState(false);
    const isAuthenticated = useMemo(() => {return authenticated}, [authenticated]);
    const isAdmin = useMemo(() => {return admin}, [admin])
    return (
        <>
            <LoadingSpinner />
            <Router>
                <Routes>
                    {!isAuthenticated && (
                        <Route path="/login" element={<Login
                            authenticate = {()=>{!isAuthenticated && setAuthenticated(true)}}
                            setAdmin= {(is_admin=false)=> setAdmin(is_admin)}
                        />}
                        />
                    )}
                    {
                        isAuthenticated ? (
                            <>
                                <Route exact path="/" element={
                                    <Accounts
                                        destroy={()=>{isAuthenticated && setAuthenticated(false)}}
                                        is_admin={admin}
                                    />
                                } />
                                <Route exact path="/countries" element={
                                    <Countries />
                                }/>
                                <Route exact path="/banks" element={
                                    <Banks />
                                }/>
                                <Route exact path="/user" element={
                                    <Settings destroy={()=>{isAuthenticated && setAuthenticated(false)}} />
                                }></Route>
                                {
                                    isAdmin ? (<>
                                        <Route exact path="/add_user" element={
                                            <Admin is_admin={isAdmin}></Admin>
                                        }/>
                                    </>) : (<></>)
                                }
                            </>
                        ) : (<></>)


                    }
                    <Route path="/*" element={<Navigate to="/login" state={{from: (routes.includes(window.location.pathname)) ?
                                                                                    window.location.pathname : null
                    }}/>} replace/>}/>
                </Routes>
            </Router>
        </>
    )
}

export default memo(App);
