import {asyncRequest} from "./Requests";

const fetchCountries = async ()=>{
    const data = await asyncRequest('get', "/countries");
    return data.response?.countries;
}

export {
    fetchCountries
};
