const userModel = {
    'name': "",
    'id': ""
};

const settings_authorized_path = [
    '/'
];

export {
    userModel,
    settings_authorized_path
}